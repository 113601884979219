import { TextField, Button, Icon } from "@mui/material";
import styles from "./MyGPT.module.css";
import { useEffect, useState } from "react";
import axios from "axios"; // For making api call to backend


const MyGPT = () => {

  // Initializes state for input url and output transcription
  const [inputURL, setinputURL] = useState('https://www.youtube.com/watch?v=zqy8v1bzVZg');
  const [transcribedText, setTransribedText] = useState('');
  const [videoID, setVideoID] = useState('');

  // Initializes api url for backend call
  var apiUrl = ''
  //let apiUrl = process.env.REACT_APP_DEV_BACKEND_URL || process.env.REACT_APP_PROD_BACKEND_URL;
 

  // Sets api url as per the environment
  if (process.env.NODE_ENV == 'development') {
    apiUrl = process.env.REACT_APP_DEV_BACKEND_URL;
  } else {
    apiUrl = process.env.REACT_APP_PROD_BACKEND_URL;
  }

  console.log(apiUrl)


  // Sends a post request using axios to a specific URL with formData and receives the response with summary data from backend
  const handleSubmit = () => {
  /*   const headers = {
      "content-type": "multipart/form-data",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Methods": ["GET", "POST"]
    };
 */
    //"Access-Control-Allow-Origin": "http://localhost:3000",

    const formData = new FormData();
    formData.append("url", inputURL)
    //add any other form data here

    axios
      .post(`${apiUrl}/api/transcribe`, formData)
      .then((response) => {
        setTransribedText(response.data.summaryText)
        setVideoID(response.data.videoID)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Callback function for handling the change event of an input field
  // Updates the state of the inputURL variable whenever the value of the input field changes
  const handleURLChange = (event) => {
    setinputURL(event.target.value);
  };

  return (
    <div className={styles.mygpt}>
      <h5 className={styles.title}>EchoGPT</h5>
      <div className={styles.input}>
        <label className={styles.label}>Youtube link</label>
        <TextField
          className={styles.textInput}
          color="primary"
          variant="filled"
          type="text"
          label="Youtube link"
          size="medium"
          margin="normal"
          value={inputURL}
          onChange={handleURLChange}
        />
        <div className={styles.submit}>
          <Button
            className={styles.submitButton}
            variant="contained"
            name="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className={styles.video}>
        <iframe width="560" height="315" src={'https://www.youtube.com/embed/' + videoID} frameBorder="" allowFullScreen></iframe>'
      </div>
      <div className={styles.output}>
        <p className={styles.trans}>{transcribedText}</p>
      </div>
    </div>
  );
};

export default MyGPT;
